
import { Component, Prop, Vue } from "nuxt-property-decorator";
import RouterLink from "~/components/atoms/text/RouterLink.vue";
import CountiesList from "~/components/molecules/display/CountiesList.vue";
import { Location } from "~/types/Location";

@Component({
  components: {
    RouterLink,
    CountiesList,
  },
})
export default class DistrictLocationsList extends Vue {
  @Prop({ default: false }) isDistrictCity!: boolean;
  @Prop({ default: false }) isDistrictPage!: boolean;
  @Prop({ default: false }) isStatePage!: boolean;
  @Prop({ required: true }) districtName!: string;
  @Prop({ required: true }) locations!: Location[];
  @Prop({ default: false }) isPellets!: boolean;
  @Prop({ type: Function, required: true }) translateStateName!: (
    name: string,
  ) => string;

  getLocationKey(location: Location, index: number): string {
    if (this.isDistrictCity) {
      const zipcode = "zipcode" in location ? location.zipcode : "";
      return `${index}-${location.name}-${zipcode}`;
    }
    return `${index}-${location.name}`;
  }

  getLocationLink(location: Location): string {
    if (this.isDistrictCity || this.isDistrictPage) {
      return location.link;
    }

    const baseUrl = this.isPellets ? "pelletspreise" : "heizoelpreise";
    return `/${baseUrl}/bundeslaender/${this.$route.params.category.toLowerCase()}/${location.link.toLowerCase()}`;
  }

  getLocationTitle(location: Location): string {
    if (this.isStatePage) {
      return `Weiter zum Landkreis ${location.name}`;
    }
    if (this.isDistrictCity || this.isDistrictPage) {
      return `Erfahren Sie mehr über die aktuelle ${this.priceType}-Entwicklung in ${location.name}`;
    }
    return `Weiter zur Ansicht zu ${location.name}`;
  }

  getLocationDisplayName(location: Location): string {
    if (this.isDistrictCity) {
      const zipcode = "zipcode" in location ? location.zipcode : "";
      return `${zipcode} ${location.name}`;
    }
    return "displayName" in location ? location.displayName : location.name;
  }

  getPostalCodeDescription(): string {
    return `Für nähere Informationen zum ${this.priceType} in den Postleitzahlen der kreisfreien Stadt ${this.translatedDistrictName}, wählen Sie eine PLZ aus der Liste.`;
  }

  getDistrictLocationDescription(): string {
    return `Für nähere Informationen zum ${this.priceType} in den Ortschaften des Landkreises ${this.translatedDistrictName}, wählen Sie einen Ort aus der Liste.`;
  }

  getCountyListDescription(): string {
    return `Für nähere Informationen zum ${this.priceType} in den Landkreisen ${this.translatedDistrictName}s wählen Sie einen Landkreis aus der Liste.`;
  }

  get sectionVariant(): string {
    return this.isPellets ? "inside-grid-pellets" : "inside-grid";
  }

  get translatedDistrictName(): string {
    return this.translateStateName(this.districtName);
  }

  get priceType(): string {
    return this.isPellets ? "Pelletspreis" : "Heizölpreis";
  }

  get districtSuffixText(): string {
    if (this.isDistrictPage) {
      return `des Landkreises ${this.translatedDistrictName}`;
    }
    return `in ${this.translatedDistrictName}`;
  }

  get headerText(): string {
    if (this.isDistrictCity) {
      return `Postleitzahlen der kreisfreien Stadt ${this.translatedDistrictName}`;
    }

    if (this.isDistrictPage) {
      if (!this.isPellets) {
        return `Weitere Ortschaften`;
      }
      return "Weitere Ortschaften mit Pelletspreisen";
    }

    return this.isPellets ? `Landkreise mit Pelletspreisen` : "Landkreise";
  }

  get descriptionText(): string {
    if (this.isDistrictCity) {
      return this.getPostalCodeDescription();
    }

    if (this.isDistrictPage) {
      return this.getDistrictLocationDescription();
    }

    return this.getCountyListDescription();
  }
}
